angular.module( 'sq.config', ['ngRoute']).constant('SETTINGS', {
  apiEndpoint: window.location.protocol + '//' + window.location.host + '/api/',
  socketIOurl: window.location.protocol + '//' + window.location.host,
  assetsUrl: window.location.protocol + '//' + window.location.host + '/assets/',
})
.config(function myAppConfig(/*@ngInject*/$locationProvider) {
  $locationProvider.html5Mode(true);
  $locationProvider.hashPrefix('!');
})
.config(function logProviderConfigFn(/*@ngInject*/$logProvider) {
  $logProvider.debugEnabled(true);
})
.config(function($httpProvider, $routeProvider, $animateProvider) {
  $httpProvider.useApplyAsync(true);
  $httpProvider.defaults.headers.delete = { "Content-Type": "application/json;charset=utf-8" };
  $routeProvider.otherwise({redirectTo: '/'});
  $animateProvider.classNameFilter(/animated/);
})
.value('maxNotifications', 99)
.constant('_', window._) //lodash
// doesn't work (injection error when used) : .constant('numeral,', window.numeral) //lodash
.constant('$MD_THEME_CSS', '')
.constant('jsyaml', window.jsyaml)
.constant('jStat', window.jStat)
.constant('AgGrid', window.AgGrid)
// For a more complete titleCase solution, which may be a bit slower
// https://gist.githubusercontent.com/jeffjohnson9046/9789876/raw/6e96dd87745ed9b319ae53c42c2f207747411313/title-case-filter.js
.filter('titleCase', () => {
  return (text) => {
    let input = text || '';
    return input.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };
})
//http://stackoverflow.com/questions/28394572/angularjs-seconds-to-time-filter/30767487#30767487
.filter('msToTime', function() {

    function padTime(t) {
        return t < 10 ? "0"+t : t;
    }

    return function(_ms) {
        if (typeof _ms !== "number" || _ms < 0) {
          return "00:00:00";
        }

        _ms = _ms / 1000;

        var hours = Math.floor(_ms / 3600),
            minutes = Math.floor((_ms % 3600) / 60),
            seconds = _ms - (hours * 3600 + minutes * 60);

        return padTime(hours) + ":" + padTime(minutes) + ":" + padTime(seconds.toFixed(3));
    };
})
.filter('limitToEllipsis', () => {
  return (inputString, allowedLength) => {
    if(!inputString) {
      return;
    }

    return inputString.length > allowedLength ? inputString.substring(0, allowedLength) + "..." : inputString;
  };
})
//https://gist.github.com/thomseddon/3511330
.filter('bytes', function() {
  return function(bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
      return '-';
    }

    if (typeof precision === 'undefined') {
      precision = 1;
    }

    var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
      number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
  };
})
//http://ericclemmons.com/angular/angular-autofocus-directive/
.directive('sqAutofocus', ['$document', function($document) {
  return {
    link: function($scope, $element, attrs) {
      setTimeout(function() {
        $element[0].focus();
      }, 100);
    }
  };
}]);
